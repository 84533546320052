import { useState } from 'react';

import style from './Header.module.css';

import { ReactComponent as LogoMenuSVG } from './../../assets/svg/LogoMenu.svg';
import { ReactComponent as MenuSVG } from './../../assets/svg/Menu.svg';
import { ReactComponent as BackSVG } from './../../assets/svg/Back.svg';
import { ReactComponent as TextSVG } from './../../assets/svg/Text.svg';
import { ReactComponent as TwoImageSVG } from './../../assets/svg/TwoImage.svg';
import { ReactComponent as ImageSVG } from './../../assets/svg/Image.svg';

function Header({ setShowMenu }) {
  const [showSelect, setShowSelect] = useState(false);

  return (
    <>
      <div className={style.header}>
        <div className={`${style.headerItem} ${style.headerItemActive}`}>Тексты</div>
        <div className={style.headerItem}>Презентации</div>
        <div className={style.headerItem}>Изображения</div>
      </div>

      <div className={style.headerMobile}>
        <div className={style.menuOpen} onClick={() => setShowMenu(true)}>
          <MenuSVG />
        </div>
        <div className={style.headerOption} onClick={() => setShowSelect(!showSelect)}>
          <span>Тексты</span>
          <div className={showSelect ? `${style.optionSvg} ${style.optionSvgActive}` : style.optionSvg}>
            <BackSVG />
          </div>
          <div className={showSelect ? `${style.optionSelect} ${style.optionSelectActive}` : style.optionSelect}>
            <span><TextSVG /> Тексты</span>
            <span><TwoImageSVG /> Презентации</span>
            <span><ImageSVG /> Изображения</span>
          </div>
        </div>
        <div className={style.headerLogo}>
          <LogoMenuSVG />
        </div>
      </div>
      {showSelect ? <div className={style.cover} onClick={() => setShowSelect(!showSelect)}></div> : <></>}

    </>
  );
}

export default Header;
