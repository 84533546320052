import { useState } from 'react';

import style from './PopupTarif.module.css';

import { ReactComponent as CircleSVG } from './../../../assets/svg/Circle.svg';
import { ReactComponent as StatusOkSVG } from './../../../assets/svg/StatusOk.svg';


const props = [
  { id: 0, title: 'Базовый', price: 0 },
  { id: 1, title: 'Стандарт', price: 390 },
  { id: 2, title: 'VIP', price: 1290 },
];

function PopupTarif() {
  /*
    priceList - Список тарифов
    active - Активный тариф по ID тарифа
    activePromo - Была ли нажата кнопка 'Ввести промокод'
    promoInput - text input промокода
    promoStatus - Статус активирован ли промокод

    Функция buy - функция покупки тарифа
    promoCheck - Проверка промокода в случае верного кода меняется статус promoStatus
  */
  const [priceList, setPriceList] = useState(props);
  const [active, setActive] = useState(1);
  const [activePromo, setActivePromo] = useState(false);
  const [promoInput, setPromoInput] = useState('');
  const [promoStatus, setPromoStatus] = useState(false);

  function buy() {

  }

  function promoCheck() {
    setPromoStatus(true);
  }

  const result = priceList.map(el => {
    return <div className={el.id === active ? `${style.input} ${style.active}` : style.input} key={el.id} onClick={() => setActive(el.id)}>
      <div className={style.checkbox}>
        {el.id === active ? <div className={style.circle}></div> : <CircleSVG />} {el.title}
      </div>
      <div className={style.price}>{el.price} ₽ <span>/месяц</span></div>
    </div>
  });

  return (
    <div className={style.content}>
      <div className={style.title}>Выберите тарифный план</div>
      <div className={style.contInput}>
        {result}
        <div className={style.but} onClick={() => buy()}>Продолжить</div>

        {
          activePromo ?
            <div className={style.promo}>
              <input type='text' placeholder='Введите код' value={promoInput} onChange={e => setPromoInput(e.target.value)}></input>
              {promoStatus ? <StatusOkSVG /> : <span onClick={() => promoCheck()}>Применить</span>}
            </div>
            :
            <div className={style.code} onClick={() => setActivePromo(true)}>Ввести промокод</div>
        }
        {promoStatus ? <div className={style.promoOk}>Промокод активирован цены на тарифы изменились</div> : <></>}

      </div>
    </div >
  );
}

export default PopupTarif;