import Chat from './views/Chat/Chat';
import { AppProvider, useAppContext } from './Context.js';
import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import Empty from './views/Empty.js';


function App() {
  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
}

function AppContent() {
  const { state, dispatch } = useAppContext();

  return (
    <BrowserRouter>
      {state.isLoading ? null : !state.isLoading&&state.authorized ? <Chat />:<Empty />}
    </BrowserRouter>
  );
}

export default App;