import { useState } from 'react';

import style from './Menu.module.css';

import { ReactComponent as LogoSVG } from './../../assets/svg/Logo.svg';
import { ReactComponent as StarSVG } from './../../assets/svg/Star.svg';
import { ReactComponent as PlusSVG } from './../../assets/svg/Plus.svg';
import { ReactComponent as UserSVG } from './../../assets/svg/User.svg';
import { ReactComponent as CloseSVG } from './../../assets/svg/Close.svg';
import { ReactComponent as MoneySVG } from './../../assets/svg/MoneyWhite.svg';

import Button from './Button/Button';
import MenuItem from './MenuItem/MenuItem';
/*
fixed - закреплен ли чат
*/
const props = [
  { id: 1, title: 'Первый чат1', fixed: true },
  { id: 2, title: 'Второй чат2', fixed: false },
  { id: 3, title: 'Третий чат3', fixed: true },
  { id: 4, title: 'Четвертый чат4', fixed: false },
];

function Menu({ showMenu, setShowMenu, setShowLk, setExportState, setShowTariff }) {
  const [menu, setMenu] = useState(props);

  function updateMenu(id, key, value) {
    const updatedProps = menu.map(obj => {
      if (obj.id === id) {
        return {
          ...obj,
          [key]: value
        };
      } else {
        return obj;
      }
    });
    setMenu(updatedProps);
  }

  function deleteMenu(itemId) {
    const newItems = menu.filter(item => item.id !== itemId);
    setMenu(newItems);
  }

  function addItem() {
    const newId = menu.length + 1;
    const newItem = { id: newId, title: 'Новый чат', titleUpdate: false, fixed: false };
    const newItems = [...menu, newItem];
    setMenu(newItems);
  }

  const sortedMenu = menu.sort((a, b) => {
    return a.fixed === b.fixed ? 0 : a.fixed ? -1 : 1;
  });

  const menuResult = sortedMenu.map(el => {
    return <MenuItem key={el.id} id={el.id} title={el.title} titleUpdate={el.titleUpdate} fixed={el.fixed} updateMenu={updateMenu} deleteMenu={deleteMenu} setExportState={setExportState} />
  });

  return (
    <div className={showMenu ? `${style.menu} ${style.active}` : style.menu}>
      <div className={style.menuClose} onClick={() => setShowMenu(false)}>
        <CloseSVG />
      </div>
      <LogoSVG className={style.logo} />
      <Button svgIcon={<PlusSVG />} onClick={addItem} />
      <div className={style.button}>
        <StarSVG className={style.svg} />
        Избранное
      </div>
      <div className={style.title}>Созданные чаты</div>
      <div className={style.cont}>
        {menuResult}
      </div>
      <div className={style.profileCont}>
        <div className={style.profile} onClick={() => setShowLk(true)}><UserSVG /> Личный кабинет</div>
        <div className={style.profile} onClick={() => setShowTariff(true)}><MoneySVG /> Оплата тарифа <div className={style.newBut}>Новое</div></div>
      </div>
    </div>
  );
}

export default Menu;
