import style from './PopupMyTarif.module.css';

function PopupMyTarif({ setShowMyTariff, setShowTariff }) {
  
  return (
    <div className={style.content}>
      <div className={style.block}>
        <span>Ваш тариф:</span><span>Стандарт</span>
      </div>
      <div className={style.block}>
        <span>Активен до:</span><span>24.07.2023</span>
      </div>
      <div className={style.but} onClick={()=>{setShowMyTariff(false); setShowTariff(true);}}>Сменить тариф</div>
    </div >
  );
}

export default PopupMyTarif;