import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import style from './MenuItem.module.css';

import { ReactComponent as MessageSVG } from './../../../assets/svg/Message.svg';
import { ReactComponent as FixSVG } from './../../../assets/svg/Fix.svg';
import { ReactComponent as FixblackSVG } from './../../../assets/svg/Fixblack.svg';
import { ReactComponent as EditSVG } from './../../../assets/svg/Edit.svg';
import { ReactComponent as DeleteSVG } from './../../../assets/svg/Delete.svg';
import { ReactComponent as ExportSVG } from './../../../assets/svg/Export.svg';
import { ReactComponent as AdditionallySVG } from './../../../assets/svg/Additionally.svg';


function ChatItem({ id, title, titleUpdate, fixed, updateMenu, deleteMenu, setExportState }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showInput, setShowInput] = useState(false);
  return (
    <NavLink className={({ isActive }) => isActive ? `${style.cont} ${style.active}` : style.cont} to={`/${id}`}>

      <MessageSVG className={style.svg} />

      {showInput ?
        <input className={style.input} type='text' value={title} onChange={(e) => updateMenu(id, 'title', e.target.value)} onBlur={() => { updateMenu(id, 'titleUpdate', !titleUpdate); setShowInput(!showInput) }} /> :
        title.length > 15 ? title.slice(0, 15) + '...' : title
      }

      {fixed ? <FixSVG className={style.fix} /> : <></>}

      <div className={style.additionally} onClick={() => { setShowPopup(!showPopup); }}>
        <AdditionallySVG />
      </div>

      {
        showPopup ? <div className={style.popup} onClick={() => { setShowPopup(!showPopup); }}>
          <span onClick={() => updateMenu(id, 'fixed', !fixed)}><FixblackSVG />{fixed ? 'Открепить' : 'Закрепить'}</span>
          <span onClick={() => { updateMenu(id, 'titleUpdate', !titleUpdate); setShowInput(!showInput) }}><EditSVG />{titleUpdate ? 'Сохранить' : 'Переименовать'}</span>
          <span onClick={() => deleteMenu(id)}><DeleteSVG />Удалить</span>
          <span onClick={() => setExportState({ idMenu: id, active: 'pdf', showExport: true })}><ExportSVG />Экспортировать</span>
        </div> : <></>
      }
      
    </NavLink >
  );
}

export default ChatItem;
