import { useState } from 'react';
import React from 'react';


import style from './Layout.module.css';

import Menu from '../components/Menu/Menu';
import Header from '../components/Header/Header';
import Popup from './../components/Popup/Popup';
import PopupLk from '../components/Popup/PopupLk/PopupLk';
import PopupExport from '../components/Popup/PopupExport/PopupExport';
import PopupTarif from '../components/Popup/PopupTarif/PopupTarif';
import PopupMyTarif from '../components/Popup/PopupMyTarif/PopupMyTarif';



function Layout({ children }) {
    const [showMenu, setShowMenu] = useState(false);
    /*
      @showLk - Видимость popup окна Личного кабинета
      @showTariff - Видимость popup окна Тарифа
      @exportState - Отвечает за export idMenu Активный чат, active формат экспорта, showExport видимость popup окна Экспорта
    */
    const [showLk, setShowLk] = useState(false);
    const [showTariff, setShowTariff] = useState(false);
    const [showMyTariff, setShowMyTariff] = useState(false);
    const [exportState, setExportState] = useState({ idMenu: 0, active: 'pdf', showExport: false });

    function exportHide() {
        let obj = { ...exportState };
        obj.showExport = false;
        setExportState(obj);
    }

    return (
        <div className={style.Chat}>
            <div className={showMenu ? `${style.menuClose} ${style.menuCloseActive}` : style.menuClose} onClick={() => setShowMenu(false)}></div>

            <Menu showMenu={showMenu} setShowMenu={setShowMenu} setShowLk={setShowLk} setExportState={setExportState} setShowTariff={setShowTariff} />

            <div className={style.cont}>

                <Header setShowMenu={setShowMenu} />

                {children}

            </div>

            <Popup
                styleCont={{ maxWidth: '490px' }}
                visible={showLk}
                title='Личный кабинет'
                content={<PopupLk setShowMyTariff={setShowMyTariff} setShowLk={setShowLk} />}
                onClose={setShowLk}
            />
            
            <Popup
                styleCont={{ maxWidth: '490px', maxHeight: '346px', }}
                visible={showMyTariff}
                title='Мой тариф'
                content={<PopupMyTarif setShowMyTariff={setShowMyTariff} setShowTariff={setShowTariff}/>}
                onClose={setShowMyTariff}
            />

            <Popup
                styleCont={{ maxWidth: '660px', maxHeight: '580px', height: '100%' }}
                visible={showTariff}
                title=''
                content={<PopupTarif setShowTariff={setShowTariff} />}
                onClose={setShowTariff}
            />

            <Popup
                styleTitle={{ textAlign: 'center', fontSize: '28px' }}
                styleCont={{ maxWidth: '480px', maxHeight: '404px', height: '100%' }}
                visible={exportState.showExport}
                title='Экспорт'
                content={<PopupExport exportState={exportState} setExportState={setExportState} />}
                onClose={exportHide}
            />
        </div >
    );
}

export default Layout;
