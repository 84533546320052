import React, { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";

const AppContext = createContext();

const initialState = {
  isLoading: true,
  authorized: false,
  CSRF_Token: "",
};


const appReducer = (state, action) => {
  switch (action.type) {
    case "setAuthorized":
      return { ...state, authorized: action.value };
    case "setIsLoading":
      return { ...state, isLoading: action.status };
    case "setToken":
      // No need to pass dispatch to handleSetToken
      return state; // Return the current state immediately
    default:
      return state;
  }
};

const getCookie=(name)=>{    
    let cookieValue = null;  
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');        
        for (let i = 0; i < cookies.length; i++) {
           
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const handleSetToken = async (state) => {
    try {
      const resp = await axios("https://aitwos.com/login.php", {
        method: "get",
        withCredentials: true,
      });
  
      const token = getCookie("jwt");
      if (token) {
        dispatch({ type: "CSRF_Token:", value: token });
        dispatch({ type: "setAuthorized", value: true });
        dispatch({ type: "setIsLoading", value: false });

      } else {
        dispatch({ type: "setAuthorized", value: false });
        dispatch({ type: "setIsLoading", value: false });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: "setAuthorized", value: false });
      dispatch({ type: "setIsLoading", value: false });
    }
  }

  // Define an internal dispatch function to handle setToken action
  const internalDispatch = (action) => {
    if (action.type === "setToken") {
      handleSetToken(state);
    }
  };

  // Trigger the token verification process on app start
  useEffect(() => {
    if (!state.authorized) {
      // Only call setToken if the user is not authorized yet.
      internalDispatch({ type: 'setToken' });
    }
  }, [state.authorized]);

  return (
    <AppContext.Provider value={{ state, dispatch: internalDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export { AppProvider, useAppContext };
