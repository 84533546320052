import ReactMarkdown from 'react-markdown';

import style from './Message.module.css';

import { ReactComponent as Reload } from './../../../assets/svg/Reload.svg';
import { ReactComponent as Aitwos } from './../../../assets/svg/Aitwos.svg';
import { ReactComponent as StarMessage } from './../../../assets/svg/StarMessage.svg';
import { ReactComponent as Copy } from './../../../assets/svg/Copy.svg';

function Message({user, message}) {
  const result = user === 'Ai' ? <>
    <div className={style.copy} onClick={() => {navigator.clipboard.writeText(message)}}>
      <Copy />
    </div>
    <div className={style.favorite}>
      <StarMessage />
    </div>
    <div className={style.icon}>
      <Aitwos />
    </div>
  </> : <>
    <div className={style.icon}>
      <span>A</span>
    </div>
    <div className={style.reload}>
      <Reload />
  </div></>;
  return (
    <div className={style.message}>
      {result}
      <div className={style.text}>
        <ReactMarkdown>{message}</ReactMarkdown>
      </div>
    </div>
  );
}

export default Message;
