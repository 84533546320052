import style from './PopupLk.module.css';

import { ReactComponent as MoneySVG } from './../../../assets/svg/Money.svg';
import { ReactComponent as PadlockSVG } from './../../../assets/svg/Padlock.svg';
import { ReactComponent as ChatSVG } from './../../../assets/svg/Chat.svg';
import { ReactComponent as LogOutSVG } from './../../../assets/svg/LogOut.svg';



function PopupLk({ setShowMyTariff, setShowLk }) {
  function logout(){
    window.location.href="https://aitwos.com/api/logout.php"
  }
  return (
    <div className={style.content}>
      <span className={style.span} onClick={() => { setShowMyTariff(true); setShowLk(false); }}><MoneySVG />Тариф и подписка</span>
      <span className={`${style.span} ${style.noactive}`}><PadlockSVG /> Приватность и безопасность</span>
      <span className={style.span} onClick={()=>{window.open("https://t.me/aitwos", "_blank", "noopener,noreferrer");}}><ChatSVG />Поддержка</span>
      <span onClick={logout} className={style.span}><LogOutSVG/> Выйти из аккаунта</span>
    </div >
  );
}

export default PopupLk;