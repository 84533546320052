import { useEffect, KeyboardEvent } from 'react';

import style from './Popup.module.css';

import { ReactComponent as CloseSVG } from './../../assets/svg/CloseBlack.svg';


function Popup({
  styleTitle,
  styleCont,
  visible = false,
  title,
  content,
  onClose,
}) {

  useEffect(() => {
  }, [visible]);

  const closePopup = () => {
    onClose(false);
  };

  const onKeydown = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      onClose(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  });

  if (!visible) {
    return null;
  }

  return (
    <div className={style.popup} onClick={closePopup}>
      <div style={styleCont} className={style.popupContent} onClick={(e) => e.stopPropagation()}>
        <div className={style.popupHeader}>
          <div style={styleTitle} className={style.title}>{title}</div>
          <span className={style.close} onClick={closePopup}>
            <CloseSVG />
          </span>
        </div>
        <div className={style.popupBody}>
          {content}
        </div>
      </div>
    </div>
  );
}

export default Popup;