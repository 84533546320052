
import TextareaAutosize from 'react-textarea-autosize';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import style from './Content.module.css';

import Message from './Message/Message';

import { ReactComponent as ClipSVG } from './../../assets/svg/Clip.svg';
import { ReactComponent as VoiceSVG } from './../../assets/svg/Voice.svg';
import { ReactComponent as SendSVG } from './../../assets/svg/Send.svg';
import { ReactComponent as CloseSVG } from './../../assets/svg/CloseBlack.svg';

const props = [
  {
    id: 0, user: 'Ai', message: `# Заголовок первого уровня
Это пример текста на Markdown. Здесь мы можем использовать различные элементы разметки, такие как:
- Списки с маркерами
- Списки с номерами
- Изображения
- Ссылки
Также мы можем добавлять **жирный текст**, *курсив* и даже.`},
  { id: 1, user: '', message: 'Привет' }
];

function Content() {
  const [textarea, setTextarea] = useState('');
  const [record, setRecord] = useState(false);
  const [file, setFile] = useState([]);
  const [disabledFile, setDisabledFile] = useState(false);

  let { chatId } = useParams();

  function handleFile(e) {
    if (e.target.files && file.length < 5) {
      setFile([...file, e.target.files[0]]);
    }
    setDisabledFile(file.length === 4 ? true : false)
  };

  function deleteFile(index) {
    setFile(file.filter((el, id) => id !== index));
  }

  const message = props.map(el => {
    return <Message key={el.id} user={el.user} message={el.message} />
  });

  /*
  Отображаем блоки в зависимости от идет ли запись звука или нет
  */

  const inputeResult = record ? <div className={style.voiceBlock}>
    <div className={style.record}>
      <div className={style.circle}></div>
      <span>00.01.03</span>
    </div>
    <div className={style.cancel}>Отмена</div>
    <div className={style.voiceRecord} onClick={() => setRecord(!record)}><VoiceSVG /></div>
  </div>
    :
    <>
      <div className={style.clip}>
        <ClipSVG />
        <input id="file" type="file" onChange={(e) => handleFile(e)} disabled={disabledFile ? "disabled" : ""} />
      </div>
      <TextareaAutosize
        className={style.input}
        placeholder='Сообщение'
        value={textarea}
        onChange={(e) => setTextarea(e.target.value)}
        minRows={1}
        maxRows={3}
      />
      {textarea.length > 0 ?
        <div className={style.voice}><SendSVG /></div>
        :
        <div className={style.voice} onClick={() => setRecord(!record)}><VoiceSVG /></div>
      }
    </>

  /*
  Отображаем блок со списком файлов
  */

  let fileRes = '';
  if (file.length > 0 && !record)
    fileRes = file.map((el, index) => {
      return <div className={style.fileCont} key={index}>
        <div className={style.fileFormat}>{el.name.split('.').pop()}</div>
        <div className={style.fileName}>
          <span>{el.name.length > 8 ? el.name.slice(0, 8) + '...' : el.name}</span>
          <span>{('' + el.size / 1024 / 1024).length > 4 ? ('' + el.size / 1024 / 1024).slice(0, 4) : el.size / 1024 / 1024} MB</span>
        </div>
        <div className={style.closeFile} onClick={() => { deleteFile(index) }}><CloseSVG /></div>
      </div>
    });

  return (
    <>
      <div className={style.chat}>
        {message}
      </div>
      <div className={file.length === 1 ? `${style.flexFileOne} ${style.flexFile}` :style.flexFile}>
        {fileRes}
      </div>
      <div className={style.inputCont}>
        {inputeResult}
      </div>
    </>
  );
}

export default Content;
