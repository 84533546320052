import style from './Button.module.css';

function Button({ svgIcon, onClick }) {
  return (
    <div className={style.button} onClick={() => onClick()}>
      {svgIcon}
      Создать чат
    </div>
  );
}

export default Button;