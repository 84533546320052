import { Routes, Route } from 'react-router-dom';

import Layout from '../Layout';
import Content from '../../components/Content/Content';

function Chat() {

  return (
    <Layout>
      <Routes>
        <Route path="/:chatId" element={<Content />} />
      </Routes>
    </Layout>
  );
}

export default Chat;
