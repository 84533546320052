import React, { useEffect } from 'react';

const Empty = () => {
  useEffect(()=>{
    window.location.href='https://aitwos.com/'
  })
  // const redirectToGoogle = () => {  
  //   window.open(
  //       'https://localhost/',
  //       '_blank' // <- This is what makes it open in a new window.
  //     );
  // };

  // return (
  //   <div style={styles.container} onClick={redirectToGoogle}>
  //     Please Log In
  //   </div>
  // );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};

export default Empty;
