import style from './PopupExport.module.css';

import { ReactComponent as WordSVG } from './../../../assets/svg/Word.svg';
import { ReactComponent as PdfSVG } from './../../../assets/svg/Pdf.svg';


function PopupExport({ exportState, setExportState }) {
  function clickBlock(active){
    let obj = {...exportState};
    obj.active = active;
    setExportState(obj);
  }
  return (
    <div className={style.content}>
      <div className={style.title}>Выберите формат экспорта</div>
      <div className={style.row}>
        <div onClick={()=>clickBlock('json')} className={exportState.active === 'json' ? `${style.block} ${style.active}` :  style.block}><WordSVG /><span>JSON</span></div>
        <div onClick={()=>clickBlock('pdf')} className={exportState.active === 'pdf' ? `${style.block} ${style.active}` :  style.block}><PdfSVG /><span>PDF</span></div>
        <div onClick={()=>clickBlock('word')} className={exportState.active === 'word' ? `${style.block} ${style.active}` :  style.block}><WordSVG /><span>Word</span></div>
      </div>
      <div className={style.button}>Экспортировать</div>
    </div >
  );
}

export default PopupExport;